import React from 'react';
import { Arrow, TitleContainer } from './components';

interface IProps {
  children: React.ReactNode;
  title: string;
  defaultOpen?: boolean;
}

export const Collapsible: React.FC<IProps> = ({
  title,
  children,
  defaultOpen = false
}) => {
  const [isOpen, setOpen] = React.useState(defaultOpen);
  return (
    <React.Fragment>
      <TitleContainer onClick={() => setOpen(!isOpen)}>
        <Arrow isOpen={isOpen} />
        <span>{title}</span>
      </TitleContainer>
      {isOpen && <React.Fragment>{children}</React.Fragment>}
    </React.Fragment>
  );
};
