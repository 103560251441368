import * as React from 'react';
import { Layout, LayoutBody } from 'searchkit/es';
import { ComparerProductPlaceholder, LayoutResults } from './components';

export const ComparerProductsPlaceholder = () => (
  <Layout>
    <LayoutBody>
      <LayoutResults>
        {Array.from(new Array(6)).map((_, index) => (
          <ComparerProductPlaceholder key={index} />
        ))}
      </LayoutResults>
    </LayoutBody>
  </Layout>
);
