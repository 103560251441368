import * as React from 'react';
import { ProductsContainer, ProductPlaceholder } from '../../components';

export const HitsPlaceholder = () => (
  <ProductsContainer>
    <ProductPlaceholder />
    <ProductPlaceholder />
    <ProductPlaceholder />
    <ProductPlaceholder />
    <ProductPlaceholder />
  </ProductsContainer>
);
