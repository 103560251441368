import * as React from 'react';
import { RangeSlider } from '../rangeSlider';
import { roundUpOrDown } from '../../helpers';
import { RoundingVariants } from '../../types';

interface IProps {
  min: number;
  max: number;
  onChange: ({ min, max }: { min: number; max: number }) => void;
  minValue: number | undefined;
  maxValue: number | undefined;
}

export const PriceRangeSlider = ({
  min,
  max,
  minValue,
  maxValue,
  onChange
}: IProps) => (
  <RangeSlider
    min={roundUpOrDown(min, RoundingVariants.DOWN)}
    max={roundUpOrDown(max, RoundingVariants.UP)}
    value={[
      minValue ? roundUpOrDown(minValue, RoundingVariants.DOWN) : min,
      maxValue ? roundUpOrDown(maxValue, RoundingVariants.UP) : max
    ]}
    onChange={([min, max]) => {
      onChange({
        min,
        max
      });
    }}
  />
);
