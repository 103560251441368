import styled from 'styled-components';
import * as React from 'react';
import { Range as RCRange } from 'rc-slider';
import { getFormattedPrice } from '../../helpers/getFormattedPrice';
import { useDebounce } from '../../hooks';
import { roundUpOrDown } from '../../helpers';
import { RoundingVariants } from '../../types';

const RCRangeSlider = styled(RCRange)`
  .rc-slider {
    position: relative;
    height: 14px;
    padding: 5px 0;
    width: 100%;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider * {
    box-sizing: border-box;
    tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-rail {
    position: absolute;
    top: 2px;
    width: 100%;
    background-color: #e9e9e9;
    height: 4px;
    border-radius: 6px;
  }
  .rc-slider-track {
    top: 2px;
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 6px;
    background-color: #4a90e2;
  }
  .rc-slider-handle {
    position: absolute;
    margin-left: -7px;
    margin-top: -5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    cursor: grab;
    border-radius: 50%;
    border: solid 2px #4a90e2;
    background-color: #fff;
    touch-action: pan-x;
  }
  .rc-slider-handle:focus {
    border-color: #57c5f7;
    box-shadow: 0 0 0 5px #4a90e2;
    outline: none;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: #4a90e2;
    box-shadow: unset;
  }
  .rc-slider-handle:hover {
    border-color: #57c5f7;
  }
  .rc-slider-handle:active {
    border-color: #57c5f7;
    cursor: grabbing;
    cursor: grabbing;
  }
  .rc-slider-mark {
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }
  .rc-slider-mark-text {
    white-space: nowrap;
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;

    &:nth-child(2),
    &:nth-child(3) {
      font-size: 10px;
    }
  }

  .rc-slider-mark-text-active {
    color: #666;
  }

  .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }

  .rc-slider-dot {
    position: absolute;
    bottom: -20px;
    margin-left: -1px;
    width: 1px;
    height: 12px;
    background-color: #e9e9e9;
    cursor: pointer;
    vertical-align: middle;

    &:first-child {
      display: none;
    }

    &:last-child {
      display: none;
    }
  }
  .rc-slider-dot-active {
    border-color: #4a90e2;
  }
  .rc-slider-disabled {
    background-color: #e9e9e9;
  }
  .rc-slider-disabled .rc-slider-track {
    background-color: #ccc;
  }
  .rc-slider-disabled .rc-slider-handle,
  .rc-slider-disabled .rc-slider-dot {
    border-color: #ccc;
    box-shadow: none;
    background-color: #fff;
    cursor: not-allowed;
  }
  .rc-slider-disabled .rc-slider-mark-text,
  .rc-slider-disabled .rc-slider-dot {
    cursor: not-allowed !important;
  }
  .rc-slider-vertical {
    width: 14px;
    height: 100%;
    padding: 0 5px;
  }
  .rc-slider-vertical .rc-slider-rail {
    height: 100%;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-track {
    left: 5px;
    bottom: 0;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-handle {
    margin-left: -5px;
    margin-bottom: -7px;
    touch-action: pan-y;
  }
  .rc-slider-vertical .rc-slider-mark {
    top: 0;
    left: 18px;
    height: 100%;
  }
  .rc-slider-vertical .rc-slider-step {
    height: 100%;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-dot {
    left: 2px;
    margin-bottom: -4px;
  }
  .rc-slider-vertical .rc-slider-dot:first-child {
    margin-bottom: -4px;
  }
  .rc-slider-vertical .rc-slider-dot:last-child {
    margin-bottom: -4px;
  }
  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    display: block !important;
    animation-play-state: paused;
  }
  .rc-slider-tooltip-zoom-down-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    display: block !important;
    animation-play-state: paused;
  }
  .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
  .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
    animation-name: rcSliderTooltipZoomDownIn;
    animation-play-state: running;
  }
  .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
    animation-name: rcSliderTooltipZoomDownOut;
    animation-play-state: running;
  }
  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    transform: scale(0, 0);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .rc-slider-tooltip-zoom-down-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  @keyframes rcSliderTooltipZoomDownIn {
    0% {
      opacity: 0;
      transform-origin: 50% 100%;
      transform: scale(0, 0);
    }
    100% {
      transform-origin: 50% 100%;
      transform: scale(1, 1);
    }
  }
  @keyframes rcSliderTooltipZoomDownOut {
    0% {
      transform-origin: 50% 100%;
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform-origin: 50% 100%;
      transform: scale(0, 0);
    }
  }
  .rc-slider-tooltip {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: visible;
    box-sizing: border-box;
    tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-tooltip * {
    box-sizing: border-box;
    tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-tooltip-hidden {
    display: none;
  }
  .rc-slider-tooltip-placement-top {
    padding: 4px 0 8px 0;
  }
  .rc-slider-tooltip-inner {
    padding: 6px 2px;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 1;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #6c6c6c;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
  }
  .rc-slider-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    bottom: 4px;
    left: 50%;
    margin-left: -4px;
    border-width: 4px 4px 0;
    border-top-color: #6c6c6c;
  }
`;

interface IProps {
  min: number;
  max: number;
  value: number[];
  onChange: (values: number[]) => void;
}

const Container = styled.div`
  position: relative;
  margin-left: 10px;
  margin-right: 18px;
  height: 45px;
`;

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Input = styled.input.attrs({ type: 'number' })`
  width: 50px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 5px;
  font-size: 16px;
`;

export const RangeSlider = ({ onChange, min, max, value }: IProps) => {
  const [values, setValues] = React.useState<number[]>([min, max]);
  const [inputValues, setInputValues] = React.useState<number[]>([min, max]);

  const debouncedValue = useDebounce(inputValues, 400);

  React.useEffect(() => setValues(debouncedValue), [debouncedValue]);

  React.useEffect(() => {
    setInputValues(value);
    setValues(value);
  }, [value]);

  const stepSize = (max - min) / 3;

  const secondMark = roundUpOrDown(min + stepSize, RoundingVariants.DOWN);

  const thirdMark = roundUpOrDown(secondMark + stepSize, RoundingVariants.UP);

  const marks = [min, secondMark, thirdMark, max].reduce(
    (acc, item) => ({
      ...acc,
      [item]: `€${getFormattedPrice(item, false)}`
    }),
    {}
  );

  return (
    <React.Fragment>
      <InputContainer>
        <Input
          value={inputValues[0] ? Math.round(inputValues[0] / 100) : ''}
          onChange={event => {
            setInputValues([Number(event.target.value) * 100, inputValues[1]]);
          }}
        />
        -
        <Input
          value={inputValues[1] ? Math.round(inputValues[1] / 100) : ''}
          onChange={event => {
            setInputValues([inputValues[0], Number(event.target.value) * 100]);
          }}
        />
      </InputContainer>
      <Container>
        <RCRangeSlider
          dots={false}
          marks={marks}
          reverse={false}
          included={false}
          onAfterChange={onChange}
          onChange={values => {
            return setValues(values);
          }}
          max={max}
          min={min}
          value={values}
        />
      </Container>
    </React.Fragment>
  );
};
