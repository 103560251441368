import * as React from 'react';
import { ColorFilterButtons } from '../../../components/filterButtons';
import { getValuesByKeys, getKeysByValue } from '../../../helpers';
import { RefinementListFilter, ListProps } from 'searchkit/es';
import { colors } from '../../../maps';

export const ColorFilterComponent = ({
  selectedItems,
  items,
  setItems
}: ListProps) => (
  <ColorFilterButtons
    active={getValuesByKeys(colors, selectedItems)}
    colors={items
      .map(({ key }) => colors[key])
      .filter(item => item !== undefined)}
    onClick={selectedColor => {
      const colorKeys = getKeysByValue(colors, selectedColor);
      if (!colorKeys) {
        return;
      }
      setItems(colorKeys);
    }}
  />
);

export const ButtonColorFilter = () => (
  <RefinementListFilter
    id="color"
    title=""
    operator="OR"
    field="color"
    listComponent={ColorFilterComponent}
  />
);
