import React from 'react';
import styled from 'styled-components';
import { Image as ImageComponent } from '../image';
import {
  ProductInformation,
  TitleContainer,
  LargeProductFilters,
  SmallProductFilters,
  ProductHeaderContainer,
  Properties
} from './components';
import { FlexContainer } from '../flexContainer';
import { Title } from '../title';

interface IProps {
  imageSrc: string | null;
  title: string | null;
  selectedFiltersComponent: React.ReactNode;
  filtersComponent: React.ReactNode;
}

const Image = styled(ImageComponent)`
  align-self: center;
`;

export const ProductHeader = ({
  imageSrc,
  title,
  selectedFiltersComponent,
  filtersComponent
}: IProps) => (
  <ProductHeaderContainer>
    <FlexContainer>
      {imageSrc && <Image maxWidth="90px" src={imageSrc} alt={title || ''} />}
      <ProductInformation>
        <TitleContainer>
          {title && <Title>{title}</Title>}
          <Properties>
            {selectedFiltersComponent && selectedFiltersComponent}
          </Properties>
        </TitleContainer>
        <LargeProductFilters>{filtersComponent}</LargeProductFilters>
      </ProductInformation>
    </FlexContainer>
    <SmallProductFilters>{filtersComponent}</SmallProductFilters>
  </ProductHeaderContainer>
);
