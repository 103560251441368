import * as React from 'react';
import { RefinementListFilter, ListProps } from 'searchkit/es';
import { Collapsible, FilterCheckboxesColor } from '../../../components';
import { Bucket } from '../../../types';
import { sortBucketAlphabetically } from '../../../helpers';

const CheckboxColorFilterComponent = ({
  items,
  toggleItem,
  selectedItems
}: ListProps) => {
  const handleToggleItem = (index: number) => toggleItem(items[index].key);

  return (
    <Collapsible defaultOpen={true} title="Kleur">
      <FilterCheckboxesColor
        active={selectedItems}
        items={items}
        onClick={handleToggleItem}
      />
    </Collapsible>
  );
};

export const CheckboxColorFilter = () => (
  <RefinementListFilter
    id="color"
    title=""
    operator="OR"
    field="color"
    listComponent={CheckboxColorFilterComponent}
    bucketsTransform={(items: Array<Bucket>) =>
      items.sort(sortBucketAlphabetically)
    }
  />
);
