import * as React from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../flexContainer';
import { Layout } from 'searchkit/es';

import {
  InfoContainer,
  PropertiesNameContainer,
  PropertiesContainer,
  DeliveryTime,
  PriceAndButtonContainer,
  ComparerProductContainer
} from '../comparerProduct/components';

import { Shimmer } from '../shimmer';
import { ProductName } from '../productName';

export const LayoutResults = styled(Layout)`
  flex: 1;
`;

const CheckboxShimmerContainer = styled(FlexContainer)`
  margin-bottom: 10px;
  align-items: center;

  &:last-child {
    margin: 0;
  }
`;

const PriceRangeShimmerContainer = styled(FlexContainer)`
  align-items: center;
`;

export const CheckboxShimmerStorage = () => (
  <CheckboxShimmerContainer>
    <Shimmer width="20px" height="20px" style={{ marginRight: '5px' }} />
    <Shimmer width="55px" height="17px" />
  </CheckboxShimmerContainer>
);

const ShimmerBall = styled(Shimmer)`
  border-radius: 50%;
`;

export const PriceRangeShimmer = () => (
  <PriceRangeShimmerContainer>
    <ShimmerBall width="20px" height="20px" />
    <Shimmer width="100px" height="5px" />
    <ShimmerBall width="20px" height="20px" />
  </PriceRangeShimmerContainer>
);

export const ComparerProductPlaceholder = () => (
  <ComparerProductContainer>
    <InfoContainer>
      <Shimmer width="80px" height="20px" />
      <PropertiesNameContainer>
        <ProductName>
          <Shimmer width="50px" height="20px" />
        </ProductName>
        <PropertiesContainer>
          <Shimmer width="30px" height="20px" />
          <Shimmer width="45px" style={{ marginLeft: '5px' }} height="20px" />
        </PropertiesContainer>
      </PropertiesNameContainer>
    </InfoContainer>
    <DeliveryTime>
      <Shimmer width="180px" height="15px" />
    </DeliveryTime>
    <PriceAndButtonContainer>
      <Shimmer width="90px" height="24px" />
      <Shimmer width="113px" height="30px" style={{ margin: '5px 0 0 20px' }} />
    </PriceAndButtonContainer>
  </ComparerProductContainer>
);
