import { Product } from '../../components/product';
import React from 'react';
import { Hits, HitsProps } from 'searchkit/es';
import { colors } from '../../maps';

interface IProps extends HitsProps {
  result: {
    _source: {
      modelType: string;
      price: number;
      storageSize: number;
      affiliateUrl: string;
      shopName: string;
      color: string;
    };
  };
}

export const ProductHitsComponent = (props: IProps) => {
  const {
    modelType,
    price,
    storageSize,
    affiliateUrl,
    shopName,
    color
  } = props.result._source;

  return (
    <Product
      name={modelType}
      price={price}
      storage={storageSize}
      shopName={shopName}
      productRedirectUrl={affiliateUrl}
      color={colors[color]}
      colorName={color}
    />
  );
};

export const ProductHits = () => <Hits itemComponent={ProductHitsComponent} />;
