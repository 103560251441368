import * as React from 'react';
import { CheckboxContainer, CheckboxInput } from './components';

interface IProps {
  children: React.ReactNode;
  checked: boolean;
  onChange: () => void;
  className?: string;
}

export const Checkbox: React.FC<IProps> = ({
  children,
  checked,
  onChange,
  className
}) => {
  return (
    <CheckboxContainer className={className}>
      <CheckboxInput checked={checked} onChange={onChange} />
      {children}
    </CheckboxContainer>
  );
};
