import { SearchkitManager } from 'searchkit/es';
import * as React from 'react';
import { get } from 'lodash-es';

interface IProductState {
  image: string | null;
  name: string | null;
}

export const useGetFirstProductFromSearchKit = (
  sk: SearchkitManager
): IProductState => {
  const [product, setProduct] = React.useState<IProductState>({
    image: null,
    name: null
  });

  React.useEffect(() => {
    // add listener to listen for result once
    sk.addResultsListener((results: any) => {
      const result = get(results, 'hits.hits[0]._source');
      if (!result) {
        return;
      }

      setProduct({
        name: result.modelType,
        image: result.imageUrl
      });

      // unregister itself
      return () => sk.resultsEmitter.clear();
    });
  }, [sk]);

  return product;
};
