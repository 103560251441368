import styled, { css } from 'styled-components';
import { Property as PropertyComponent } from '../property';
import { ColorProperty as ColorPropertyComponent } from '../property';

export const DeleteButton = styled.button`
  background-color: transparent;
  font-size: 13px;
  cursor: pointer;
  font-weight: 700;
  height: 100%;
  border-right: none;
  border-bottom: none;
  border-top: none;
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  margin-left: 6px;
  padding: 3px 8px 3px 6px;
`;

const propertyStyle = css`
  padding: 0 0 0 7px;
  margin: 5px 5px;
  display: inline-block;
`;

export const Property = styled(PropertyComponent)`
  ${propertyStyle}
`;

export const ColorProperty = styled(ColorPropertyComponent)`
  ${propertyStyle}
`;
