import styled from 'styled-components';

export const Property = styled.span`
  text-transform: uppercase;
  user-select: none;
  color: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  border-radius: 2px;
  padding: 1px 4px;
  white-space: nowrap;
`;
