import * as React from 'react';
import { CheckboxComponent as Checkbox } from './components';
import { FacetRow } from '../FacetRow';

interface IProps {
  items: Array<{ key: string; doc_count: number }>;
  onClick: (index: number) => void;
  active?: Array<string>;
}

export const FilterCheckboxes: React.FC<IProps> = ({
  active = [],
  items,
  onClick
}) => (
  <React.Fragment>
    {items.map(({ key, doc_count }, index: number) => (
      <Checkbox
        key={index}
        checked={active.includes(key)}
        onChange={() => onClick(index)}
      >
        <FacetRow label={key} number={doc_count} />
      </Checkbox>
    ))}
  </React.Fragment>
);
