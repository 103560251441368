import styled from 'styled-components';

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  margin: 0;

  :focus {
    outline: none;
  }

  &:checked {
    background: #4a90e2;
  }

  &:checked:after {
    content: '';
    bottom: 4px;
    left: 5.5px;
    position: absolute;
    width: 4px;
    height: 10px;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(45deg);
  }
`;
