import * as React from 'react';
import {
  PriceAndButtonContainer,
  ProductContainer,
  ProductInformation,
  ProductInformationContainer,
  ShopImage,
  ProductDataContainer,
  Property
} from './components';
import { ProductName } from '../productName';
import { ColorProperty } from '../property';
import { Price } from '../price';
import { SponsoredAnchorButton } from '../buttons';
import { getPromotionLabel } from '../../helpers';

interface IProduct {
  name: string;
  price: number;
  storage: number;
  shopName: string;
  productRedirectUrl: string;
  color: string;
  colorName: string;
}

export const Product: React.FC<IProduct> = ({
  productRedirectUrl,
  storage,
  price,
  name,
  color,
  colorName,
  shopName
}) => {
  return (
    <ProductContainer>
      <ProductInformationContainer>
        <ShopImage shopName={shopName} />
        <ProductInformation>
          <ProductDataContainer>
            <ProductName>{name}</ProductName>
            <div>
              <Property>{storage}gb</Property>
              <ColorProperty color={color}>{colorName}</ColorProperty>
            </div>
          </ProductDataContainer>
        </ProductInformation>
      </ProductInformationContainer>
      <PriceAndButtonContainer>
        <Price price={price} href={productRedirectUrl} />
        <SponsoredAnchorButton
          aria-label={getPromotionLabel({
            shopName,
            productName: name
          })}
          href={productRedirectUrl}
        >
          Bekijken
        </SponsoredAnchorButton>
      </PriceAndButtonContainer>
    </ProductContainer>
  );
};
