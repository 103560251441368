import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Image as ImageComponent } from '../image';
import { breakpoints, media } from '../../helpers';

const imageMap: { [key: string]: string } = {
  mediamarkt: 'media-markt',
  yourmacstore: 'yourmacstore.nl'
};

const Image = styled(ImageComponent)`
  object-fit: contain;
  max-height: 40px;
`;

const ImageContainer = styled.div`
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.sm(`
    min-width: 120px;
  `)}
`;

interface IProps {
  shopName: string;
  className?: string;
}

const getSrc = (shopName: string): string => {
  const urlShopName = shopName.toLowerCase();
  return `${process.env.REACT_APP_CDN_URL}/static/shop-images/${imageMap[
    urlShopName
  ] || urlShopName}.png`;
};

export const ShopImage = ({ className, shopName }: IProps) => {
  const { containerWidth } = React.useContext(ThemeContext);
  return (
    <ImageContainer className={className}>
      <Image
        maxWidth={containerWidth <= breakpoints.sm ? '90px' : '100px'}
        alt={shopName}
        src={getSrc(shopName)}
      />
    </ImageContainer>
  );
};
