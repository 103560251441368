import * as React from 'react';
import { colors } from '../../maps';
import { DeleteButton, ColorProperty, Property } from './components';
import { formatSelectedPriceRange } from './helpers/formatSelectedPriceRange';

enum FilterTypes {
  FILTER_TYPE_STORAGE = 'storage',
  FILTER_TYPE_COLOR = 'color',
  FILTER_TYPE_SHOP = 'shop',
  FILTER_TYPE_PRICE = 'price'
}

interface IProps {
  removeFilter: (filter: any) => void;
  labelValue: string;
  filterId: string;
}

export const SelectedFilter = ({
  filterId,
  labelValue,
  removeFilter
}: IProps) => {
  switch (filterId) {
    case FilterTypes.FILTER_TYPE_COLOR:
      return (
        <ColorProperty color={colors[labelValue]}>
          {labelValue}
          <DeleteButton onClick={removeFilter}>X</DeleteButton>
        </ColorProperty>
      );
    case FilterTypes.FILTER_TYPE_STORAGE:
      return (
        <Property>
          {labelValue}GB
          <DeleteButton onClick={removeFilter}>X</DeleteButton>
        </Property>
      );
    case FilterTypes.FILTER_TYPE_SHOP:
      return (
        <Property>
          {labelValue}
          <DeleteButton onClick={removeFilter}>X</DeleteButton>
        </Property>
      );
    case FilterTypes.FILTER_TYPE_PRICE:
      return (
        <Property>
          {formatSelectedPriceRange(labelValue)}
          <DeleteButton onClick={removeFilter}>X</DeleteButton>
        </Property>
      );
    default: {
      return (
        <Property>
          {labelValue}
          <DeleteButton onClick={removeFilter}>X</DeleteButton>
        </Property>
      );
    }
  }
};
