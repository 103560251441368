import * as React from 'react';
import styled from 'styled-components';
import { media } from '../../../helpers';
import { ResetFiltersDisplayProps } from 'searchkit/es';

interface IProps {
  hasFilters: boolean;
}

const ClearFilterButtonComponent = styled.button`
  display: ${({ hasFilters }: IProps) => (hasFilters ? 'inline-flex' : 'none')};
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #4a90e2;
  flex-shrink: 0;
  font-size: 12px;
  padding: 0;
  margin-top: 10px;
  align-self: center;

  ${media.lg(`
    align-self: flex-start;
  `)}
`;

export interface IClearFilterButtonProps extends ResetFiltersDisplayProps {
  className?: string;
}

export const ClearFilterButton: React.FC<IClearFilterButtonProps> = ({
  resetFilters,
  hasFilters,
  className
}) => {
  const handleOnClick = () => resetFilters();
  return (
    <ClearFilterButtonComponent
      className={className}
      hasFilters={hasFilters}
      onClick={handleOnClick}
    >
      Alle filters verwijderen
    </ClearFilterButtonComponent>
  );
};
