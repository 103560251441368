import * as React from 'react';
import { Property } from './property';
import { Color } from '../color';

interface IProps {
  color: string;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

export const ColorProperty = ({ color, children, className }: IProps) => (
  <Property className={className}>
    <Color color={color} size="9" /> {children}
  </Property>
);
