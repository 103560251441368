import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.span`
  margin-left: 5px;
`;

const Number = styled.span`
  font-size: 11px;
  color: rgba(0, 0, 0, 0.4);
`;

interface IProps {
  number: number;
  label: string;
}

export const FacetRow = ({ number, label }: IProps) => (
  <Container>
    <Label>{label}</Label>
    <Number>{number}</Number>
  </Container>
);
