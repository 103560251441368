import * as React from 'react';
import { ResetFilters, ResetFiltersDisplayProps } from 'searchkit/es';
import {
  ClearFilterButton,
  IClearFilterButtonProps
} from './clearFilterButton';

interface IProps {
  Button?: React.ComponentType<IClearFilterButtonProps>;
}

export const ClearFilter = ({ Button = ClearFilterButton }: IProps) => (
  <ResetFilters
    component={(props: ResetFiltersDisplayProps) => <Button {...props} />}
  />
);
