import ReactDOM from 'react-dom';
import { createInArticleWidget } from './inArticle';
import { parseWidgetProps } from '../../helpers';

interface IWidgetProps {
  productSlug: string;
}

const WIDGET_CLASSNAME = '.in-article-widget';

const attach = (el: Element) => {
  const widgetProps = parseWidgetProps<IWidgetProps>(el);

  if (!widgetProps.productSlug) {
    throw Error('Missing data-product-slug attribute');
  }

  const parentElement = el.parentElement as HTMLElement;

  ReactDOM.render(
    createInArticleWidget(parentElement, widgetProps.productSlug),
    el
  );
};

const detach = (el: Element) => {
  ReactDOM.unmountComponentAtNode(el);
};

// @ts-ignore
window.iCultureVergelijker = window.iCultureVergelijker || {};
// @ts-ignore
window.iCultureVergelijker.inArticleWidget = {
  attach,
  detach,
  WIDGET_CLASSNAME
};
