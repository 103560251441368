import * as React from 'react';
import { Shimmer } from '../shimmer';
import { FlexContainer } from '../flexContainer';
import {
  ProductContainer,
  ProductInformation,
  PriceAndButtonContainer,
  ProductInformationContainer
} from './components';

export const ProductPlaceholder = () => (
  <ProductContainer>
    <ProductInformationContainer>
      <Shimmer width="90px" height="90px" />
      <ProductInformation>
        <FlexContainer>
          <Shimmer style={{ marginLeft: '10px' }} width="120px" height="30px" />
        </FlexContainer>
      </ProductInformation>
    </ProductInformationContainer>
    <PriceAndButtonContainer>
      <Shimmer width="90px" height="30px" />
      <Shimmer width="90px" height="30px" />
    </PriceAndButtonContainer>
  </ProductContainer>
);
