import * as React from 'react';
import {
  ProductInformation,
  LargeProductFilters,
  SmallProductFilters,
  ProductHeaderContainer,
  Properties,
  TitleContainer
} from './components';
import { Shimmer } from '../shimmer';
import { Space } from '../space';
import { FlexContainer } from '../flexContainer';

export const ProductHeaderPlaceHolder = () => (
  <ProductHeaderContainer>
    <FlexContainer>
      <Shimmer width="70px" height="90px" style={{ marginRight: '20px' }} />
      <ProductInformation>
        <TitleContainer>
          <Shimmer
            width="100px"
            height="20px"
            style={{ marginRight: '10px' }}
          />
          <Properties>
            <Shimmer width="30px" height="20px" />
            <Shimmer width="30px" style={{ marginLeft: '5px' }} height="20px" />
          </Properties>
        </TitleContainer>
        <LargeProductFilters>
          <Shimmer width="90px" height="20px" />
          <Shimmer width="90px" style={{ marginLeft: '10px' }} height="20px" />
        </LargeProductFilters>
      </ProductInformation>
    </FlexContainer>
    <SmallProductFilters>
      <Shimmer width="90px" height="20px" />
      <Space />
      <Shimmer width="90px" height="20px" />
    </SmallProductFilters>
  </ProductHeaderContainer>
);
