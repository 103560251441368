import * as React from 'react';
import { FilterButtons } from '../../../components/filterButtons';
import { RefinementListFilter, ListProps } from 'searchkit/es';
import { Bucket } from '../../../types';
import { sortBucketNumerically } from '../../../helpers';

const StorageFilterComponent = ({
  selectedItems,
  items,
  setItems
}: ListProps) => (
  <FilterButtons
    label="Opslag"
    active={selectedItems.map(item => `${item}gb`)}
    items={items.map(({ key }) => `${key}gb`)}
    onClick={selected => {
      setItems([selected.replace('gb', '')]);
    }}
  />
);

interface IProps {
  className?: string;
}

export const ButtonStorageFilter = ({ className }: IProps) => (
  <div className={className}>
    <RefinementListFilter
      className={className}
      id="storage"
      title=""
      operator="OR"
      label="Opslag"
      field="storageSize"
      listComponent={StorageFilterComponent}
      bucketsTransform={(items: Array<Bucket>) =>
        items.sort(sortBucketNumerically)
      }
    />
  </div>
);
