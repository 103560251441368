import * as React from 'react';
import { RefinementListFilter, ListProps } from 'searchkit/es';
import { Bucket } from '../../../types';
import {
  FilterCheckboxes,
  CheckboxShimmerStorage,
  Collapsible
} from '../../../components';
import { formatStorageSize, sortBucketNumerically } from '../../../helpers';

const CheckboxStorageFilterComponent = ({
  selectedItems,
  items,
  toggleItem
}: ListProps) => {
  const handleOnClick = (selectedIndex: any) =>
    toggleItem(items[selectedIndex].key);

  if (!items.length) {
    return Array.from(new Array(6)).map((_, index) => (
      <CheckboxShimmerStorage key={index} />
    ));
  }

  return (
    <Collapsible defaultOpen={true} title="Opslag">
      <FilterCheckboxes
        active={selectedItems.map((selectedItem: string) =>
          formatStorageSize(Number(selectedItem))
        )}
        items={items.map(({ key, ...rest }) => ({
          key: formatStorageSize(key),
          ...rest
        }))}
        onClick={handleOnClick}
      />
    </Collapsible>
  );
};

export const CheckboxStorageFilter = () => (
  <RefinementListFilter
    id="storage"
    title=""
    operator="OR"
    field="storageSize"
    listComponent={CheckboxStorageFilterComponent}
    bucketsTransform={(items: Array<Bucket>) =>
      items.sort(sortBucketNumerically)
    }
  />
);
