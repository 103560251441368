import styled from 'styled-components';
import { FlexContainer } from '../flexContainer';
import { media } from '../../helpers';
import { ShopImage as ShopImageContainer } from '../shopImage';
import { Property as PropertyComponent } from '../property';

export const ProductContainer = styled(FlexContainer)`
  background-color: white;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  box-shadow: #dddddd 0 1px 3px 0;
  padding: 15px 10px;

  &:last-child {
    margin: 0;
  }

  ${media.sm(`
    flex-direction: row;
    padding: 15px;
  `)}
`;

export const ProductInformation = styled(FlexContainer)`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  flex-shrink: 0;

  ${media.sm(`
  margin: 0 0 0 20px;
  align-items: flex-start;
`)}
`;

export const PriceAndButtonContainer = styled(FlexContainer)`
  justify-content: space-between;
  align-self: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  ${media.sm(`
  margin-top: 0;
  max-width: 450px;
  align-self: center;
`)}
`;

export const ProductInformationContainer = styled(FlexContainer)`
  width: 100%;
  justify-content: space-between;

  ${media.sm(`
  justify-content: flex-start;
`)}
`;

export const ProductDataContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-end;
  ${media.sm(`
    align-items: flex-start;
  `)}
`;

export const ShopImage = styled(ShopImageContainer)`
  margin-top: 5px;
  align-items: flex-start;
  justify-content: flex-start;
  ${media.sm(`
    margin-right: 20px;
    justify-content: center;
    margin-right: 0;
  `)}
`;

export const Property = styled(PropertyComponent)`
  margin-right: 5px;
`;
