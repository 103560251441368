import * as React from 'react';
import { colors } from '../../maps';
import { CheckboxColor, CheckboxComponent as Checkbox } from './components';
import { FacetRow } from '../FacetRow';

interface IProps {
  items: Array<{ key: string; doc_count: number }>;
  onClick: (index: number) => void;
  active?: Array<string>;
}

export const FilterCheckboxesColor: React.FC<IProps> = ({
  active = [],
  items,
  onClick
}) => (
  <React.Fragment>
    {items.map(({ key, doc_count }, index: number) => (
      <Checkbox
        key={index}
        checked={active.includes(key)}
        onChange={() => onClick(index)}
      >
        <CheckboxColor color={colors[key]} size="17" />
        <FacetRow label={key} number={doc_count} />
      </Checkbox>
    ))}
  </React.Fragment>
);
