import styled from 'styled-components';
import { Checkbox } from '../checkbox';
import { Color } from '../color';

export const CheckboxComponent = styled(Checkbox)`
  margin-left: 5px;
  margin-bottom: 10px;
`;

export const CheckboxColor = styled(Color)`
  margin-left: 5px;
`;
