import * as React from 'react';
import styled from 'styled-components';
import { media } from '../../helpers';
import { Image } from '../image';

interface IShopLogo {
  shopImageUrl: string;
  shopName: string;
}

const ShopLogoImage = styled(Image)`
  margin-right: 0;
  height: 100%;

  ${media.sm(`
  margin-right: 40px;
  `)}
`;

export const ShopLogo: React.FC<IShopLogo> = ({ shopImageUrl, shopName }) => (
  <ShopLogoImage maxWidth="70px" src={shopImageUrl} alt={shopName} />
);
