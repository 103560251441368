import { NoHits as SKNoHits } from 'searchkit/es';
import { ErrorMessage, Message } from '../../components/errorMessage';
import * as React from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components';

const ErrorContainer = styled(FlexContainer)`
  justify-content: center;
  height: 100%;
`;

export const NoHits = () => (
  <SKNoHits
    component={() => (
      <ErrorContainer>
        <Message>Geen resultaten gevonden.</Message>
      </ErrorContainer>
    )}
    errorComponent={({ resetSearchFn }: { resetSearchFn: () => void }) => (
      <ErrorContainer>
        <ErrorMessage onClick={resetSearchFn} />
      </ErrorContainer>
    )}
  />
);
