import styled, { css } from 'styled-components';

export const TitleContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: flex;
  cursor: pointer;
  user-select: none;
`;

interface IArrowProps {
  isOpen: boolean;
}

export const Arrow = styled.div<IArrowProps>`
  width: 5px;
  height: 5px;
  border-left: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  transform: rotate(135deg);
  align-self: center;
  margin-right: 15px;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(-45deg);
    `}
`;
