import * as React from 'react';
import { ListProps, RefinementListFilter } from 'searchkit/es';
import { FilterCheckboxes, Collapsible } from '../../../components';
import { sortBucketAlphabetically } from '../../../helpers';
import { Bucket } from '../../../types';
const CheckboxModelFilterComponent = ({
  items,
  toggleItem,
  selectedItems
}: ListProps) => {
  const handleToggleItem = (index: number) => toggleItem(items[index].key);
  return (
    <Collapsible defaultOpen={true} title="Model">
      <FilterCheckboxes
        active={selectedItems}
        items={items}
        onClick={handleToggleItem}
      />
    </Collapsible>
  );
};

export const CheckboxModelFilter = () => (
  <RefinementListFilter
    id="modelType"
    title=""
    operator="OR"
    field="modelType"
    listComponent={CheckboxModelFilterComponent}
    bucketsTransform={(items: Array<Bucket>) =>
      items.sort(sortBucketAlphabetically)
    }
  />
);
