import * as React from 'react';
import { RefinementListFilter, ListProps } from 'searchkit/es';
import { Bucket } from '../../../types';
import {
  FilterCheckboxes,
  Collapsible,
  CheckboxShimmerStorage
} from '../../../components';
import { sortBucketAlphabetically } from '../../../helpers';

const CheckboxShopnameFilter = ({
  selectedItems,
  items,
  toggleItem
}: ListProps) => {
  if (!items.length) {
    return Array.from(new Array(6)).map((_, index) => (
      <CheckboxShimmerStorage key={index} />
    ));
  }

  return (
    <Collapsible title="Winkel" defaultOpen={false}>
      <FilterCheckboxes
        active={selectedItems.map((selectedItem: string) => selectedItem)}
        items={items.map(({ key, ...rest }) => ({
          key,
          ...rest
        }))}
        onClick={selectedIndex => {
          toggleItem(items[selectedIndex].key);
        }}
      />
    </Collapsible>
  );
};

export const CheckboxShopFilter = () => (
  <RefinementListFilter
    id="shop"
    title=""
    operator="OR"
    field="shopName"
    listComponent={CheckboxShopnameFilter}
    bucketsTransform={(items: Array<Bucket>) =>
      items.sort(sortBucketAlphabetically)
    }
  />
);
