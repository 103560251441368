import * as React from 'react';
import {
  ComparerProductContainer,
  DeliveryTime,
  InfoContainer,
  PriceAndButtonContainer,
  PriceComponent as Price,
  PropertiesContainer,
  PropertiesNameContainer,
  SponsoredAnchorButton,
  ShopImage,
  Property,
  ColorProperty
} from './components';
import { ProductName } from '../productName';
import { formatStorageSize, getPromotionLabel } from '../../helpers';

interface IProps {
  name: string;
  price: number;
  storage: number;
  shopName: string;
  productRedirectUrl: string;
  color: string;
  colorName: string;
  deliveryTime: string;
}

export const ComparerProduct: React.FC<IProps> = ({
  name,
  price,
  storage,
  shopName,
  deliveryTime,
  productRedirectUrl,
  color,
  colorName
}) => (
  <ComparerProductContainer>
    <InfoContainer>
      <ShopImage shopName={shopName} />
      <PropertiesNameContainer>
        <ProductName>{name}</ProductName>
        <PropertiesContainer>
          <Property>{formatStorageSize(storage)}</Property>
          <ColorProperty color={color}>{colorName}</ColorProperty>
        </PropertiesContainer>
      </PropertiesNameContainer>
    </InfoContainer>
    <DeliveryTime>{deliveryTime}</DeliveryTime>
    <PriceAndButtonContainer>
      <Price price={price} href={productRedirectUrl} />
      <SponsoredAnchorButton
        aria-label={getPromotionLabel({
          shopName,
          productName: name
        })}
        href={productRedirectUrl}
      >
        Naar aanbieding
      </SponsoredAnchorButton>
    </PriceAndButtonContainer>
  </ComparerProductContainer>
);
