import * as React from 'react';
import { DynamicRangeFilter } from 'searchkit/es';
import { RangeProps } from 'searchkit/src/components/ui/range/RangeProps';
import {
  PriceRangeSlider,
  PriceRangeShimmer,
  Collapsible
} from '../../../components';

export const PriceRangeFilter = () => (
  <DynamicRangeFilter
    field="price"
    id="price"
    title=""
    rangeComponent={({
      onFinished,
      min,
      max,
      minValue,
      maxValue
    }: RangeProps) => {
      if (!(min && max)) {
        return <PriceRangeShimmer />;
      }
      return (
        <Collapsible title="Prijs" defaultOpen={true}>
          <PriceRangeSlider
            onChange={onFinished}
            min={min}
            max={max}
            maxValue={maxValue}
            minValue={minValue}
          />
        </Collapsible>
      );
    }}
  />
);
