import styled, { css } from 'styled-components';
import { media } from '../../helpers';
import { SponsoredAnchorButton as Button } from '../buttons';
import { FlexContainer } from '../flexContainer';
import { Price } from '../price';
import { ShopImage as ShopImageContainer } from '../shopImage';
import {
  Property as PropertyComponent,
  ColorProperty as ColorPropertyComponent
} from '../property';

export const ShopImage = styled(ShopImageContainer)`
  justify-content: flex-start;
  ${media.lg(`
    justify-content: center;
  `)}
`;

export const ComparerProductContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: space-evenly;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  padding: 15px 0;

  ${media.lg(`
    justify-content: space-between;
    flex-direction: row;
  `)}

  &:last-child {
    margin: 0;
    border: none;
  }
`;

export const InfoContainer = styled(FlexContainer)`
  align-items: flex-start;
  justify-content: space-between;

  ${media.lg(`
  align-items: center;
  justify-content: flex-start;
  margin: 0 20px 0 0;
  min-width: 260px;
  max-width: 360px;
  flex: 1;
`)};
`;

export const PriceAndButtonContainer = styled(FlexContainer)`
  justify-content: space-between;
  flex-wrap: wrap;

  ${media.lg(`
    align-items: center;
    justify-content: flex-end;
  `)};
`;

export const DeliveryTime = styled.div`
  white-space: nowrap;
  font-size: 12px;
  align-self: flex-start;
  flex: 1;
  margin: 10px 0;

  ${media.lg(`
    flex: 1;
    align-self: center;
    margin: 0;
  `)};
`;

export const PriceComponent = styled(Price)`
  margin: 5px 0 0 0;

  ${media.lg(`
    margin: 0;
  `)}
`;

export const SponsoredAnchorButton = styled(Button)`
  margin: 0;

  ${media.lg(`
  margin: 5px 0 0 20px; // I think this is the most clean and appropriate way to make the button and price look good together after they wrap - a little margin-top!
  `)}
`;

export const PropertiesNameContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-end;

  ${media.lg(`
  margin: 0 0 0 20px;
  min-width: 150px;
  flex-direction: column;
  align-items: flex-start;
`)};
`;

export const PropertiesContainer = styled.div`
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;

  ${media.lg(`
  margin: 0;
`)}
`;

const propertyCss = css`
  margin: 0 0 5px 5px;

  &:first-child {
    margin-top: 0;
  }

  ${media.lg(`
    &:first-child {
      margin-left: 0; 
    }
  `)}
`;

export const ColorProperty = styled(ColorPropertyComponent)`
  ${propertyCss}
`;

export const Property = styled(PropertyComponent)`
  ${propertyCss}
`;
